import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { type SFTPConfigurationData } from '../types'

const ZConfigurationDetails = z.object({
  protocolId: z.string(),
  connectionName: z.string(),
  description: z.string(),
  protocolTypeId: z.string(),
  protocolName: z.string(),
  ipAddress: z.string(),
  port: z.number(),
  userName: z.null(),
  password: z.null(),
  tenantId: z.string(),
  createdBy: z.string(),
  createdAt: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: ZConfigurationDetails
})

export type CreateSFTPConfigurationApiResponse = z.infer<
  typeof ZConfigurationDetails
>
export type ApiResponse = z.infer<typeof ZApiResponse>

export async function UpdateConfigurationProtocol (
  protocolId: string,
  UpdateConfigirationFetchRequest: SFTPConfigurationData,
  onSuccess: (httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/app-config-service/api/v1/udp/configure-protocol/${protocolId}`

  interceptedAxios
    .put(url, UpdateConfigirationFetchRequest)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.http_status)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(
            error.response.data.error_message,
            error.response.data.http_status
          )
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
