import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAssetOrGroupAssignments = z.object({
  assetOrGroupId: z.string(),
  assetOrGroupValue: z.string()
})

export const ZSchdulerDetails = z.object({
  reportSchedulerId: z.string(),
  schedulerName: z.string(),
  reportTemplateId: z.string().nullable(),
  templateName: z.string().nullable(),
  schedulerIntervalId: z.string().nullable(),
  schedulerInterval: z.string().nullable(),
  schedulerLocationId: z.string().nullable(),
  schedulerLocation: z.string().nullable(),
  recurrencePattern: z.string().nullable(),
  reportMonth: z.number().nullable(),
  dayOfTheMonth: z.number().nullable(),
  dayOfTheWeek: z.string().nullable(),
  time: z.string().nullable(),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
  tenantId: z.string().nullable(),
  schedulerAssignmentId: z.string().nullable(),
  schedulerAssignment: z.string().nullable(),
  cronExpression: z.string().nullable(),
  lastScheduleDate: z.string().nullable(),
  nextScheduleDate: z.string().nullable(),
  lastScheduleStatus: z.string().nullable(),
  schedulerStatus: z.string().nullable(),
  modifiedAt: z.string().nullable(),
  assetOrGroupAssignments: z.array(ZAssetOrGroupAssignments)
})

export const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZSchdulerDetails)
})

export type SchdulerData = z.infer<typeof ZSchdulerDetails>
export type SchdulerApiResponse = z.infer<typeof ZApiResponse>

export async function getReportSchedulerById (
  reportSchedulerId: string,
  onSuccess: (firmwareListData: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/scheduler/sftp/data/${reportSchedulerId}`
  interceptedAxios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
