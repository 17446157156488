import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface FirmwareCreateCampaignRequest {
  firmwareCampaignName: string
  assetGroupIds: string[]
  schedulerType: string
  firmwareFileId: string
}

const ZFirmwareCreateCampaignConfigObj = z.object({
  firmwareCampaignId: z.string(),
  firmwareCampaignName: z.string()
})

const ZFirmwareCreateCampaignApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  data: ZFirmwareCreateCampaignConfigObj,
  error_message: z.optional(z.string())
})

const ZFirmwareCreateCampaignApiErrorResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type FirmwareCreateCampaignConfigObj = z.infer<
  typeof ZFirmwareCreateCampaignConfigObj
>

export type FirmwareCreateCampaignApiResponse = z.infer<
  typeof ZFirmwareCreateCampaignApiResponse
>

export async function postFirmwareCreateCampaign (
  createCampaignBody: FirmwareCreateCampaignRequest,
  onSuccess: (
    data: FirmwareCreateCampaignConfigObj,
    httpStatus: number
  ) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-config-service/api/v1/udp/firmwareCampaign/createFirmwareCampaign'

  interceptedAxios
    .post(url, { ...createCampaignBody, status: 'active' })
    .then((response) => {
      const parseResponse = ZFirmwareCreateCampaignApiResponse.safeParse(
        response.data
      )
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.data, parseResponse.data.http_status)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore next
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareCreateCampaignApiErrorResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
