import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZInput = z.object({
  projectId: z.string(),
  user: z.object({
    sub: z.string()
  })
})

const ZPermissions = z.object({
  input: ZInput
})

const ZOperationPermissions = z.object({
  can_add_asset: z.boolean(),
  can_add_asset_config: z.boolean(),
  can_allow_access: z.boolean(),
  can_authorize_user: z.boolean(),
  can_create_honeywell_user: z.boolean(),
  can_create_tenant: z.boolean(),
  can_create_user: z.boolean(),
  can_delete_honeywell_user: z.boolean(),
  can_delete_tenant: z.boolean(),
  can_delete_user: z.boolean(),
  can_modify_asset: z.boolean(),
  can_modify_asset_config: z.boolean(),
  can_modify_file_provisioning: z.boolean(),
  can_read_all_roles: z.boolean(),
  can_read_all_users: z.boolean(),
  can_read_asset: z.boolean(),
  can_read_asset_alarm: z.boolean(),
  can_read_asset_config: z.boolean(),
  can_read_asset_data: z.boolean(),
  can_read_file_provisioning: z.boolean(),
  can_read_honeywell_user: z.boolean(),
  can_read_kpis: z.boolean(),
  can_read_tenant: z.boolean(),
  can_read_tenant_logo: z.boolean(),
  can_read_user: z.boolean(),
  can_read_user_logo: z.boolean(),
  can_update_honeywell_user: z.boolean(),
  can_update_tenant: z.boolean(),
  can_update_user: z.boolean(),
  is_active_user: z.boolean()
})

export type PermissionsRequest = z.infer<typeof ZPermissions>
export type OperationPermission = z.infer<typeof ZOperationPermissions>

export async function getPermissions (
  subId: string,
  onSuccess: (successResponse: OperationPermission) => any,
  onFailure: (error: boolean, httpStatus: number) => any
): Promise<void> {
  const url = '/opa/v1/data/udp/adminportal'

  const permissionsRequest: PermissionsRequest = {
    input: {
      projectId: window._env_?.REACT_APP_FORGE_PROJECT_ID ?? '',
      user: {
        sub: subId
      }
    }
  }

  interceptedAxios
    .post(url, permissionsRequest)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZOperationPermissions.safeParse(response.data.result)
        // istanbul ignore else
        if (parseResponse.success) {
          onSuccess(parseResponse.data)
        } else {
          onFailure(true, response.status)
        }
      } else {
        onFailure(true, response.status)
      }
    })
    .catch((error) => {
      onFailure(true, error.status)
    })
}
