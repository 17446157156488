import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAlarm = z.object({
  alarmDefinitionId: z.string(),
  assetId: z.string(),
  assetName: z.string(),
  alarmName: z.string(),
  severity: z.string(),
  alarmStatus: z.string(),
  readingTime: z.string()
})

const ZAlarmData = z.object({
  totalCount: z.number(),
  alarms: z.array(ZAlarm)
})

const ZApiResponse = z.object({
  http_status: z.any(),
  data: ZAlarmData,
  error: z.boolean()
})

export type TopIssues = z.infer<typeof ZAlarmData>

export async function getTopIssues (
  tenantId: string,
  onSuccess: (deviceList: any) => any,
  onFailure: (errorMessage: any) => any
): Promise<void> {
  const url = `/alarm-service/api/v1/udp/assets/alarms/severity/critical?tenantId=${tenantId}&size=5000&sortdir=asc&sortfield=assetId`

  interceptedAxios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
          // istanbul ignore else
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data)
          } else {
            onFailure(parseResponse.data.error)
          }
        } else {
          onFailure(parseResponse.error.message)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
