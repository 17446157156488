import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export const Ztag = z.object({
  name: z.string()
})

export const ZDeviceList = z.object({
  id: z.string(),
  name: z.string(),
  serial_number: z.string(),
  firmware_version: z.string(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  install_date: z.string().nullable(),
  removal_date: z.string().nullable(),
  tag: z.array(Ztag),
  state: z.string().nullable(),
  device_type: z.string().nullable().optional(),
  comm_module_serial_number: z.string().nullable().optional()
})

export const ZData = z.object({
  total_count: z.number(),
  assets: ZDeviceList.array()

})

export const ZApiResponse = z.object({
  http_status: z.any(),
  data: z.optional(ZData),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type DeviceList = z.infer<typeof ZDeviceList>
export type Tag = z.infer<typeof Ztag>

export const getSortField: any = (sortfield: string): string => {
  if (sortfield === 'serial_number') {
    sortfield = 'serialNumber'
  } else if (sortfield === 'install_date') {
    sortfield = 'installDate'
  } else if (sortfield === 'removal_date') {
    sortfield = 'removalDate'
  } else if (sortfield === 'state') {
    sortfield = 'lifecycleDetails.stateDefinition'
  } else if (sortfield === 'firmware_version') {
    sortfield = 'firmwareVersion'
  }
  return sortfield
}

export async function getDeviceList (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  tenantId: string,
  onSuccess: (deviceList: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const sortfieldparam = getSortField(sortfield)
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const url = `/assetrest/api/v1/udp/assets/tenantId?page=${page}&size=${size}&sort=${sortfieldparam},${sortdir}&tenantId=${tenantId}`

  interceptedAxios.get(url)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== null && parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.error, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore next
      if (error.response !== undefined) {
        onFailure((error.response.data !== undefined || error.response.data !== null) ? error.response.data.error_message : 'Network Error', error.response.status)
      } else {
        onFailure('Network Error', 404)
      }
    })
}
