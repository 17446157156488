import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface ConfigurationTemplate {
  asset_type_id: string
  template_name: string
}

export interface ParamValue {
  param_mapping_id: string
  value: string
}

export interface ConfigurationTemplateRequest {
  configuration_template: ConfigurationTemplate
  param_values: ParamValue[]
}

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.boolean(),
  error: z.boolean()
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function createConfiguration (
  tenantId: string,
  configurationTemplate: ConfigurationTemplateRequest,
  onSuccess: (successResponse: ApiResponse) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/commands/configuration/template?tenantId=${tenantId}`

  interceptedAxios
    .post(url, configurationTemplate)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
