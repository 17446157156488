import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const AssetTag = z.object({
  name: z.string().nullable()
})

const ZAssetTags = z.array(AssetTag)

export type AllTags = z.infer<typeof ZAssetTags>

export async function getAllTagsById (
  search: string,
  onSuccess: (Tags: AllTags, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/assetrest/api/v1/udp/tagmanagement/tags?search=${search}`
  interceptedAxios.get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZAssetTags.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
          const parsedData: AllTags = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status)
    })
}
