import interceptedAxios from '../../../utils/interceptedAxios'
import { ZApiResponse } from './getAllSchdulerList'

export async function getAllSchdulerListBySearch (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  parameter: string,
  tenantId: string,
  onSuccess: (firmwareListData: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/scheduler/search?&parameter=${parameter}&page=${page}&size=${size}&sort=${sortfield},${sortdir}&tenantId=${tenantId}`
  interceptedAxios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
