import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZAlarm = z.object({
  alarmDefinitionId: z.string(),
  alarmName: z.string(),
  severity: z.string(),
  alarmStatus: z.string(),
  readingTime: z.string()
})

const ZAlarmData = z.object({
  assetId: z.string(),
  readingTime: z.string(),
  alarmDetails: z.array(ZAlarm),
  http_status: z.any(),
  error_message: z.optional(z.string())
})

export type AlarmData = z.infer<typeof ZAlarmData>

export async function getAlarmsById (
  id: string,
  tenantId: string,
  onSuccess: (alarmsData: AlarmData, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/alarm-service/api/v1/udp/assets/alarms/${id}?tenantId=${tenantId}`

  interceptedAxios.get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZAlarmData.safeParse(response.data.data)
        // istanbul ignore else
        if (parseResponse.success) {
          const parsedData: AlarmData = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore next
      if (error.response?.data !== undefined) {
        onFailure((error.response.data.error_message !== undefined) ? error.response.data.error_message : 'Network Error', error.response.status)
      } else {
        onFailure('Network Error', 404)
      }
    })
}
