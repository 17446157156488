import interceptedAxios from '../../../utils/interceptedAxios'
import { exportFirmwareCampaignDetailsHandleFileName } from './handleName/exportFirmwareCampaignDetailsHandleFileName'

export async function exportFirmwareCampaignDetailsGrid (
  firmwareCampaignId: string,
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/firmwareCampaign/downloadCampaignDetailsById?firmwareCampaignId=${firmwareCampaignId}`
  interceptedAxios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      exportFirmwareCampaignDetailsHandleFileName(response)
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error)
    })
}
