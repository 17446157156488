import interceptedAxios from '../../../utils/interceptedAxios'
import { exportFirmwareUpgradeStatusHandleFileName } from './handleName/exportFirmwareUpgradeStatusHandleFileName'

export async function exportFirmwareUpgradeStatusGrid (
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = '/asset-config-service/api/v1/udp/firmwareCampaign/download'
  interceptedAxios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      exportFirmwareUpgradeStatusHandleFileName(response)
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error)
    })
}
