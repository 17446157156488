/* istanbul ignore file */
import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'
import LanguageStrings from '../../../i18n/locales'

const ZAddFirmware = z.object({
  message: z.string()
})

export interface firmwareReq {
  assetType: string
  firmwareType: string
  version: string
  imageIdentifier: string
  tag: string
  minimumMeterFirmwareVersion: string
  minimumCommunicationFirmwareVersion: string
  assetCategoryId: string
  assetTypeId: string
  file: File
}

const ZFirmwareAssetTypeData = z.object({
  assetTypeMasterId: z.string(),
  assetType: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(z.any())
})
const ZApiFailResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  error_message: z.string()
})

export type FirmwareAssetTypeData = z.infer<typeof ZFirmwareAssetTypeData>

const APIErrorStrings = LanguageStrings().ErrorStrings

export type AddFirmware = z.infer<typeof ZAddFirmware>

export async function addFirmware (
  firmwareRequestParams: firmwareReq,
  loggedInUser: string,
  _tenantId: string,
  onSuccess: (message: string, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const objData: any = {
    uploadedBy: loggedInUser,
    uploadType: 'firmware',
    firmwareType: firmwareRequestParams.firmwareType,
    assetType: firmwareRequestParams.assetType,
    version: firmwareRequestParams.version,
    minimumMeterVersion: firmwareRequestParams.minimumMeterFirmwareVersion,
    minimumCommunicationVersion: firmwareRequestParams.minimumCommunicationFirmwareVersion,
    imageIdentifier: firmwareRequestParams.imageIdentifier,
    assetCategoryId: firmwareRequestParams.assetCategoryId,
    assetTypeId: firmwareRequestParams.assetTypeId
  }
  const formData = new FormData()
  const blob = new Blob([firmwareRequestParams.file], { type: 'application' })
  formData.append('file', blob, firmwareRequestParams.file.name)
  formData.append('fileUpload', '' + JSON.stringify(objData))

  const url = '/fileuploadservice/api/v1/udp/ami/devices/uploadFile'
  interceptedAxios.post(url, formData)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZAddFirmware.safeParse(response.data.data)
        if (parseResponse.success) {
          const parsedData: string = parseResponse.data.message
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      if (error.response !== null && error.response !== undefined) {
        if (error.response.status === 502) {
          onFailure(APIErrorStrings.BadGateway, error.response.status)
        } else if (error.response.status === 404) {
          onFailure(error.message, error.response.status)
        } else {
          if (error.response.data !== null && Object.prototype.toString.call(error.response.data) === '[object String]') {
            onFailure(error.response.data, error.response.status)
          } else {
            const parseResponse = ZApiFailResponse.safeParse(error.response.data)
            if (parseResponse.success) {
              const parsedData = parseResponse.data.error_message
              onFailure(parsedData, error.response.status)
            } else {
              onFailure(parseResponse.error.message, error.response.status)
            }
          }
        }
      } else {
        if (error.response !== null && error.response !== undefined) {
          onFailure(error.message, error.response.status)
        } else {
          onFailure('Network Error', 500)
        }
      }
    })
}

export async function getFirmwareTypeData (
  onSuccess: (FirmwareTypeData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/fileuploadservice/api/v1/udp/ami/devices/firmware/master/firmwareType'

  interceptedAxios.get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response.status)
    })
}
