import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZassignmentsDropdownDetails = z.object({
  schedulerAssignmentId: z.string(),
  schedulerAssignment: z.string()
})

const ZintervalsDropdownDetails = z.object({
  schedulerIntervalId: z.string(),
  schedulerInterval: z.string()
})

const ZlocationsDropdownDetails = z.object({
  schedulerLocationId: z.string(),
  schedulerLocation: z.string()
})

const ZData = z.object({
  assignments: ZassignmentsDropdownDetails.array(),
  intervals: ZintervalsDropdownDetails.array(),
  locations: ZlocationsDropdownDetails.array()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: ZData
})

export type ReportDropdownMasterDetails = z.infer<typeof ZApiResponse>
export type CreateSchedulerReportDropDownData = z.infer<typeof ZData>

export async function getAllCreateSchdulerDropdown (
  onSuccess: (SchdulerDropDownListData: CreateSchedulerReportDropDownData, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = '/report-service/api/v1/udp/scheduler/metadata'
  interceptedAxios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status)
    })
}
