import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface FirmwareGroupUnassignRequest {
  firmwareCampaignId: string
  groupIds: string[]
}

const ZFirmwareGroupUnassignApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(z.boolean()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

const ZFirmwareGroupUnassignErrorApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type FirmwareGroupUnassignApiResponse = z.infer<typeof ZFirmwareGroupUnassignApiResponse>
export type FirmwareGroupUnassignErrorApiResponse = z.infer<typeof ZFirmwareGroupUnassignErrorApiResponse>

export async function PostfirmwareUnassignGroup (
  firmwareGroupUnassignBody: FirmwareGroupUnassignRequest,
  onSuccess: (httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-config-service/api/v1/udp/firmwareCampaign/unassign-group'

  interceptedAxios
    .put(url, firmwareGroupUnassignBody)
    .then((response) => {
      console.log('aaaa')
      if (response.status === 200) {
        console.log('bbbb', response)
        const parseResponse = ZFirmwareGroupUnassignApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            console.log('ccc', parseResponse)
            onSuccess(response.status)
          } else {
            console.log('ddddd', parseResponse)
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          console.log('eee', response)
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        console.log('ffff', response)
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      console.log('gggg', error)
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareGroupUnassignErrorApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          // istanbul ignore next
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
