/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios'
import { oidcConfig } from '../forgeiam/authConfig'

const defaultTimeout = window._env_?.REACT_APP_API_DEFAULT_TIMEOUT ?? '15000'
const defaultSeperators = (window._env_?.REACT_APP_API_CONFIG_SEPERATORS ?? ':/*').split('/')

// Utility function to parse the API_TIMEOUT_MAP environment variable
const parseTimeoutMap = (timeoutMap: string): Record<string, number> => {
  return timeoutMap.split(defaultSeperators[1]).reduce<Record<string, number>>((acc, pair) => {
    const [key, value] = pair.split(defaultSeperators[0])
    const val = parseInt(value)
    acc[key] = val || parseInt(defaultTimeout)
    return acc
  }, { default: parseInt(defaultTimeout) })
}

const timeoutMap = parseTimeoutMap(window._env_?.REACT_APP_API_TIMEOUT_MAP ?? '')

const interceptedAxios = axios.create()

interceptedAxios.interceptors.request.use(
  (config) => {
    const itemKey = `oidc.user:${oidcConfig.authority ?? ''}:${oidcConfig.client_id ?? ''}`
    const itemValue = sessionStorage.getItem(itemKey)
    const token = (itemValue != null) ? JSON.parse(itemValue).id_token : ''
    const username = (itemValue != null) ? JSON.parse(itemValue).profile.name : ''
    const tenantId = (itemValue != null) ? JSON.parse(itemValue).profile.customer : ''
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    if (username) {
      config.headers['X-Username'] = username
    }
    if (tenantId) {
      config.headers['X-TenantId'] = tenantId
    }

    const apiName = config.url ?? ''
    // check if the any key in the timeoutMap matches the apiName
    const timeout = Object.keys(timeoutMap).find((key) => apiName.includes(key))
    config.timeout = timeoutMap[timeout ?? 'default']
    config.headers['X-Timeout'] = config.timeout
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default interceptedAxios
