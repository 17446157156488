import { z } from 'zod'
import { type AllConfigData } from '../redux/allConfigSlice'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZData = {
  description: z.string(),
  spec_version: z.string(),
  name: z.string(),
  public_key: z.string(),
  file_created: z.string(),
  encryption_enabled_state: z.boolean(),
  protocol: z.string(),
  sftp_host_name: z.string(),
  url: z.string(),
  username: z.string(),
  password: z.string(),
  sftp_port: z.number(),
  sftp_public_key: z.string(),
  sftp_encryption_string: z.string(),
  tenant_id: z.string(),
  file_format: z.string(),
  enabled: z.boolean(),
  minutes_between_publishing: z.number(),
  hour_of_day_to_start: z.number(),
  minute_of_day_to_start: z.number(),
  minutes_after_hour: z.number(),
  frequency_type: z.string()
}

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.object(ZData),
  error: z.boolean()
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function createConfiguration (
  configurationTemplate: AllConfigData,
  onSuccess: (successResponse: ApiResponse) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/datapublisher/api/v1/udp/datapublisher'

  interceptedAxios
    .post(url, configurationTemplate)
    .then((response) => {
      if (response.status === 201) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          onSuccess(parseResponse.data)
        } else {
          onFailure('Error occurred while processing response', response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
