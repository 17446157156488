import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZParameter = z.object({
  param_mapping_id: z.string(),
  param_name: z.string(),
  param_id: z.string(),
  sample_value: z.string().nullable(),
  command_type: z.number()
})

const ZAssetConfigParamsMapping = z.object({
  assetConfigParamsMapping: z.array(ZParameter)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: ZAssetConfigParamsMapping.nullable(),
  error: z.boolean()
})

export type AssetConfigParamsMapping = z.infer<typeof ZAssetConfigParamsMapping>

export type ParameterData = z.infer<typeof ZParameter>

export async function getParameterList (
  assetTypeId: string,
  onSuccess: (parameterList: ParameterData[], httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/commands/configuration/${assetTypeId}`

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (
          parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
        ) {
          const paramMapping = parseResponse.data.data.assetConfigParamsMapping.filter((param) => param.command_type !== 0)
          onSuccess(paramMapping, response.status)
        } else {
          onFailure(parseResponse.data.error, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response.status)
    })
}
