/* eslint-disable */
// istanbul ignore file
import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZDeviceProvisioningData = z.object({
  provisioningDetailsId: z.string().nullable(),
  assetId: z.string().nullable(),
  name: z.string().nullable(),
  serialNumber: z.string().nullable(),
  configuration: z.string().nullable(),
  state: z.string().nullable(),
  assetGroup: z.string().nullable(),
  provisioningDetailsStatus: z.string().nullable(),
  provisioningErrorDescription: z.string().nullable(),
  deviceType: z.string().nullable(),
  provisioningHistoryId: z.string().nullable()
})

const ZData = z.object({
  total_count: z.number(),
  provisioningDetails: ZDeviceProvisioningData.array()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZData)
})

const ZApiFilterResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(z.array(z.string()))
})

export type DeviceProvisioningData = z.infer<typeof ZDeviceProvisioningData>

export async function getDeviceProvisioningData (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  provisioningDetailsId: number,
  search: any,
  tenantId: string,
  onSuccess: (deviceProvisioningData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details/${provisioningDetailsId}?page=${page}&size=${size}&sort=${sortfield},${sortdir}&search=${search}&tenantId=${tenantId}`
  interceptedAxios
  .get(url)
  .then((response) => {
    if (response.status === 200) {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    }
  })
  .catch((error) => {
    if(error.response){
      onFailure(error.message, error.response.status)
    }
  })
}


export async function getAssetProvisioingByIdFilteredData (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  provisioningDetailsId: number,
  filterParams: any,
  tenantId: string,
  onSuccess: (deviceProvisioningData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details/filter/${provisioningDetailsId}?&page=${page}&size=${size}&sort=${sortfield},${sortdir}&tenantId=${tenantId}`
  interceptedAxios
  .post(url, filterParams)
  .then((response) => {
    if (response.status === 200) {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    }
  })
  .catch((error) => {
    onFailure(error.message, error.response.status)
  })
}
