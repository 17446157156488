import interceptedAxios from '../../../utils/interceptedAxios'
import { handleExportDetails } from './handleName/handleExportDetails'
export async function exportAssetProvisioningDetails (
  fileId: string,
  tenantId: string,
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details/download?fileId=${fileId}&tenantId=${tenantId}`
  interceptedAxios
    .get(url, { responseType: 'blob' })
    .then((response) => {
      handleExportDetails(response)
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
