// istanbul ignore file
import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface SeedRequest {
  seed: string
}

const ZSeedApiResponse = z.object({
  http_status: z.number(),
  data: z.boolean(),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type SeedApiResponse = z.infer<typeof ZSeedApiResponse>

export async function postSeed (
  seedBody: SeedRequest,
  onSuccess: (responseData: boolean, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/assetrest/api/v1/udp/assets/tenant/seed'

  interceptedAxios
    .put(url, seedBody)
    .then((response) => {
      const parseResponse = ZSeedApiResponse.safeParse(response.data)
      // istanbul ignore next
      if (parseResponse.success) {
        if (response.data.data !== null && response.data.data !== undefined) {
          const responseData = response.data.data === 'true'
          onSuccess(responseData, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.status)
      } else {
        onFailure(error.message, 500)
      }
    })
}
