import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const AssetTag = z.object({
  name: z.string()
})

const ZAsset = z.object({
  id: z.string(),
  name: z.string(),
  serial_number: z.string(),
  device_type: z.string(),
  comm_type: z.string(),
  rem_bat_percent: z.number().nullable(),
  comm_mod_serial: z.string().nullable(),
  asset_state: z.string(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  install_date: z.string().nullable(),
  firmware_version: z.string().nullable(),
  removal_date: z.string().nullable(),
  ip_address: z.string().nullable(),
  port: z.string().nullable(),
  tag: z.array(AssetTag)
})

export type Asset = z.infer<typeof ZAsset>

export async function getAssetById (
  id: string,
  tenantId: string,
  onSuccess: (assetData: Asset, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  let locale = 'en-US'

  // istanbul ignore next
  if (navigator.language.startsWith('es') || navigator.language.startsWith('Spanish')) {
    locale = 'es-ES'
  }
  const url = `/assetrest/api/v1/udp/assets/tenantId/${id}?locale=${locale}&tenantId=${tenantId}`

  interceptedAxios.get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZAsset.safeParse(response.data.data)
        if (parseResponse.success) {
          const parsedData: Asset = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response.status)
    })
}
