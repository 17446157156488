import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZParams = z.object({
  group_id: z.string(),
  group_name: z.string(),
  status: z.string()
})

const ZConfiguration = z.object({
  groups: z.array(ZParams),
  template_id: z.string(),
  status_summary: z.string(),
  assets_associated: z.number(),
  template_name: z.string(),
  asset_type_id: z.string(),
  status: z.string(),
  asset_type_name: z.string(),
  category_type_id: z.string(),
  category_type_name: z.string(),
  failed_asset_count: z.number()
})

const ZConfigurationTemplate = z.object({
  total_count: z.number(),
  configuration_status: z.array(ZConfiguration)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZConfigurationTemplate.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type Configuration = z.infer<typeof ZConfigurationTemplate>
export type ConfigurationObj = z.infer<typeof ZConfiguration>
export type GroupParaObj = z.infer<typeof ZParams>

export async function getConfigurationListForStatus (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  tenantId: string,
  onSuccess: (configurationList: Configuration, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  if (sortfield === 'template_name') {
    sortfield = 'templateName'
  } else if (sortfield === 'asset_type_name') {
    sortfield = 'assetTypeName'
  } else if (sortfield === 'assets_associated') {
    sortfield = 'assetsAssociated'
  } else if (sortfield === 'status') {
    sortfield = 'status'
  } else if (sortfield === 'failed_asset_count') {
    sortfield = 'failedAssetCount'
  } else if (sortfield === 'status_summary') {
    sortfield = 'statusSummary'
  }
  const url = `/asset-config-service/api/v1/udp/commands/configuration/status?tenantId=${tenantId}&page=${page}&size=${size}&sort=${sortfield},${sortdir}`

  interceptedAxios
    .get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, parseResponse.data.http_status)
          } else {
            onFailure(parseResponse.data.error_message, parseResponse.data.http_status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response?.data !== undefined) {
        onFailure(error.response.data.error_message, error.response.status)
      } else {
        onFailure(error.response.message, error.response.status)
      }
    })
}
