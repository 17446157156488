import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAssetUpgradeStatus = z.object({
  success: z.number(),
  failure: z.number(),
  inProgress: z.number(),
  notStarted: z.number(),
  notApplicable: z.number()
})

const ZAssetGroupDTOList = z.object({
  group_id: z.string(),
  group_name: z.string(),
  status: z.string()
})

const ZfirmwareCampaign = z.object({
  firmwareCampaignId: z.string(),
  firmwareFileId: z.string().nullable(),
  firmwareCampaignName: z.string(),
  assetType: z.string(),
  firmwareType: z.string(),
  firmwareFile: z.string().nullable(),
  scheduledFrom: z.string().nullable(),
  finishedOn: z.string().nullable(),
  status: z.string(),
  assetUpgradeStatus: ZAssetUpgradeStatus,
  assetGroupDTOList: z.array(ZAssetGroupDTOList)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: ZfirmwareCampaign
})

const ZErrorApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type firmwareCampaignDetailsData = z.infer<typeof ZfirmwareCampaign>
export type firmwareCampaignDetailsApiResponse = z.infer<typeof ZApiResponse>
export type firmwareCampaignDetailsApiErrorResponse = z.infer<typeof ZErrorApiResponse>

export async function firmwareGridDetailsService (
  firmwareCampaignId: string,
  tenantId: string,
  onSuccess: (firmwareCampaignDetailsData: firmwareCampaignDetailsData, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/firmwareCampaign/${firmwareCampaignId}`
  await interceptedAxios.get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            // istanbul ignore next
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        const parseResponse = ZErrorApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          // istanbul ignore next
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
