import { type Option } from '../../../../components/GenXDropdown'

export const CREATE_REPORT_TYPES: Option[] = [
  { label: 'CSV', value: '.csv' }
]

export const REPORT_TYPES: Option[] = [
  { label: 'CSV', value: '.csv' },
  { label: 'CMEP', value: '.cmep' },
  { label: 'CMEP-1997', value: '.cmep-1997' },
  { label: 'CMEP-2008', value: '.cmep-2008' }
]
