import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZParams = z.object({
  param_mapping_id: z.string(),
  param_name: z.string(),
  param_value: z.string()
})

const ZConfigurationDetails = z.object({
  template_id: z.string(),
  template_name: z.string(),
  asset_type_id: z.string(),
  status: z.string().nullable(),
  asset_type_name: z.string(),
  category_type_id: z.string(),
  category_type_name: z.string(),
  params: z.array(ZParams)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: ZConfigurationDetails.nullable(),
  error: z.boolean()
})

export type ConfigurationDetails = z.infer<typeof ZConfigurationDetails>
export type Params = z.infer<typeof ZParams>

export async function getConfigurationDetailsById (
  templateId: string,
  onSuccess: (configurationDetails: ConfigurationDetails, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/commands/configuration/templates/${templateId}`

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (
          parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.error, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch(
      // istanbul ignore next
      (error) => {
      // istanbul ignore next
        onFailure(error.message, error.response.status)
      })
}
