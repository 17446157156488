import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface FirmwareGroupAssignRequest {
  groupIds: string[]
  firmwareCampaignId: string
}

const ZFirmwareGroupAssignApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(z.boolean()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

const ZFirmwareGroupAssignErrorApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type FirmwareGroupAssignApiResponse = z.infer<typeof ZFirmwareGroupAssignApiResponse>
export type FirmwareGroupAssignErrorApiResponse = z.infer<typeof ZFirmwareGroupAssignErrorApiResponse>

export async function postAssignFirmwareGroup (
  firmwareGroupAssignBody: FirmwareGroupAssignRequest,
  onSuccess: (httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/asset-config-service/api/v1/udp/firmwareCampaign/assign-group'

  interceptedAxios
    .post(url, firmwareGroupAssignBody)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZFirmwareGroupAssignApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(response.status)
          } else {
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareGroupAssignErrorApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          // istanbul ignore next
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
