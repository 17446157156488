import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { type CreateReportData } from '../types'

const ZEditReportTemplateApiResponse = z.object({
  templateId: z.string(),
  templateName: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: ZEditReportTemplateApiResponse,
  error: z.boolean()
})

export type EditReportTemplateApiResponse = z.infer<typeof ZEditReportTemplateApiResponse>
export type ApiResponse = z.infer<typeof ZApiResponse>

export async function editReportTemplate (
  templateId: string,
  reportTemplateModifyRequest: CreateReportData,
  onSuccess: (httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/reportService/reportTemplate?templateId=${templateId}`

  interceptedAxios
    .put(url, reportTemplateModifyRequest)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.http_status)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.data.http_status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
