import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZKpi = z.object({
  kpi_definition_id: z.string(),
  timestamp: z.string(),
  kpi_value: z.number()
})

type KpiData = z.infer<typeof ZKpi>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const seperateKpiData = (kpiData: KpiData[]) => {
  const cellularSignalData = kpiData.filter((kpi) => kpi.kpi_definition_id === '7bffd070-348c-4096-9852-15f729c3534d')
  const signalQualityData = kpiData.filter((kpi) => kpi.kpi_definition_id === '8a35f202-cdf6-4775-8639-5cf23a622262')
  const transmissionSignalData = kpiData.filter((kpi) => kpi.kpi_definition_id === '70ab7c7d-18dc-41f0-ba11-6269f2cc32ee')
  return { cellularSignalData, signalQualityData, transmissionSignalData }
}

export type cellularSignal = z.infer<typeof ZKpi>
export type signalQuality = z.infer<typeof ZKpi>
export type transmissionSignal = z.infer<typeof ZKpi>

export async function getSignalData (
  tenantId: string,
  assetId: string,
  from: string,
  to: string,
  onSuccess: (cellularData: cellularSignal[], signalQuality: signalQuality[], transmissionSignal: transmissionSignal[], httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  // Move the KPIList out soon
  const encodedFrom = from.replace('+', '%2B')
  const encodedTo = to.replace('+', '%2B')
  const kpiList = '7bffd070-348c-4096-9852-15f729c3534d,8a35f202-cdf6-4775-8639-5cf23a622262,70ab7c7d-18dc-41f0-ba11-6269f2cc32ee'
  const url = `/kpirest/api/v1/udp/kpis?kpiList=${kpiList}&assetId=${assetId}&from=${encodedFrom}&to=${encodedTo}&tenantId=${tenantId}`
  interceptedAxios.get(url)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZKpi.array().safeParse(response.data.data)
        if (parseResponse.success) {
          const { cellularSignalData, signalQualityData, transmissionSignalData } = seperateKpiData(parseResponse.data)
          onSuccess(cellularSignalData, signalQualityData, transmissionSignalData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response.status)
    })
}
