import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface AssociateAssetsRequest {
  template_id: string
  asset_type_id: string
  assets: string[]
}

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.boolean(),
  error: z.boolean()
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function assignAssetsToConfiguration (
  assetsRequest: AssociateAssetsRequest,
  onSuccess: (successResponse: ApiResponse) => any,
  onFailure: (errorMessage: string, httpStatus: number | undefined) => any
): Promise<void> {
  const url = '/asset-config-service/api/v1/udp/commands/configuration/associate'

  interceptedAxios
    .put(url, assetsRequest)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
