/* eslint-disable */
import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const annonymousError = 'Cannot invoke \"com.honeywell.udp.fileprocessing.entity.nonexecutablecode.ProvisioningHistory.getProvisioningHistoryId()\" because the return value of \"com.honeywell.udp.fileprocessing.repository.nonexecutablecode.ProvisioningHistoryRepository.findByFileIdAndTenantId(java.util.UUID, String)\" is null'

const ZDeviceDetails = z.object({
  provisioningDetailsId: z.string().nullable(),
  assetId: z.string().nullable(),
  name: z.string().nullable(),
  serialNumber: z.string().nullable(),
  configuration: z.string().nullable(),
  state: z.string().nullable(),
  assetGroup: z.string().nullable(),
  provisioningDetailsStatus: z.string().nullable(),
  provisioningErrorDescription: z.string().nullable(),
  deviceType: z.string().nullable(),
  provisioningHistoryId: z.string().nullable()
})

const ZData = z.object({
  total_count: z.number(),
  provisioningDetails: ZDeviceDetails.array()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZData),
  error_message: z.optional(z.string())
})

const ZApiFilterResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(z.array(z.string()))
})


export type DeviceDetails = z.infer<typeof ZDeviceDetails>

export async function getDeviceDetails (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  fileId: number,
  search: any,
  tenantId: string,
  onSuccess: (deviceData: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details?page=${page}&size=${size}&sort=${sortfield},${sortdir}&fileId=${fileId}&search=${search}&tenantId=${tenantId}`
  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined && error.response.data !== undefined) {
        const parseResponse = ZApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          // istanbul ignore next
          if (parseResponse.data.http_status === 500 && parseResponse.data.error_message === annonymousError) {
            onFailure('Asset Provisioning is in Progress', 500)
          } else {
            onFailure('Unknown Error', error.response.status)
          }
        }
      }
      else {
        onFailure(error.message, error.status)
      }
    })
}


export async function getAssetProvisioningDetailsFilteredData (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  fileId: number,
  filterParams: any,
  tenantId: string,
  onSuccess: (deviceData: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details/filter?page=${page}&size=${size}&sort=${sortfield},${sortdir}&fileId=${fileId}&tenantId=${tenantId}`
  interceptedAxios
    .post(url, filterParams)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response.status)
    })
}


export async function getAssetProvisioningHistoryDetailsFilterDeviceType (
  tenantId: string,
  onSuccess: (provisioningHistoryDetailsFilterDeviceType: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details/deviceType?tenantId=${tenantId}`
  interceptedAxios.get(url)
    .then((response) => {
      const parseResponse = ZApiFilterResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status)
    })}


export async function getAssetProvisioningHistoryDetailsFilterState (
  tenantId: string,
  onSuccess: (provisioningHistoryDetailsStateFilter: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details/state?tenantId=${tenantId}`
  interceptedAxios.get(url)
    .then((response) => {
      const parseResponse = ZApiFilterResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status)
    })}

