import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZDeviceList = z.object({
  id: z.string(),
  name: z.string(),
  serial_number: z.string(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  install_date: z.string().nullable(),
  removal_date: z.string().nullable()
})

const ZData = z.object({
  total_count: z.number(),
  assets: ZDeviceList.array()

})

const ZApiResponse = z.object({
  httpStatus: z.any(),
  data: z.optional(ZData),
  error: z.boolean()
})

export type DeviceList = z.infer<typeof ZDeviceList>

export async function getDeviceList (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  onSuccess: (deviceList: any) => any,
  onFailure: (errorMessage: any) => any
): Promise<void> {
  // istanbul ignore else
  if (sortfield === 'serial_number') {
    sortfield = 'serialNumber'
  } else if (sortfield === 'install_date') {
    sortfield = 'installDate'
  } else if (sortfield === 'removal_date') {
    sortfield = 'removalDate'
  }
  const url = `/assetrest/api/v1/udp/assets?page=${page}&size=${size}&sort=${sortfield},${sortdir}`

  interceptedAxios.get(url)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          // istanbul ignore else
          if (parseResponse.data.data !== null && parseResponse.data.data !== undefined) {
            onSuccess(parseResponse.data.data)
          } else {
            onFailure(parseResponse.data.error)
          }
        } else {
          onFailure(parseResponse.error.message)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
