import interceptedAxios from '../../../utils/interceptedAxios'

export async function exportGroupDetailsService (
  assetGroupId: string,
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/assetrest/api/v1/udp/groupManagement/details/download?assetGroupId=${assetGroupId}`
  interceptedAxios
    .get(url, { responseType: 'blob' })
    .then((response: any) => {
      // istanbul ignore next
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/csv' })
      )
      const link = document.createElement('a')
      link.href = url
      const today = new Date()
      const time = today.toLocaleTimeString([], { hourCycle: 'h23', minute: '2-digit' })
      link.setAttribute(
        'download',
        `AssetGroup${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()} ${today.getHours()}_${time}.csv`
      )
      // istanbul ignore next
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
